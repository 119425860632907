import { PayInMethodDto } from 'types/dtos/pay-in-method'
import { PayInMethodModel } from 'types/models/pay-in-method'

export const transformPayInMethodDto = ({
  id,
  note,
  code,
  icon,
  enabled,
  preferred,
  translated_name,
  event_tracking_code,
  requires_credit_card,
  method_change_possible,
}: PayInMethodDto): PayInMethodModel => {
  return {
    id,
    code,
    icon,
    enabled,
    preferred,
    note: note || null,
    translatedName: translated_name,
    eventTrackingCode: event_tracking_code,
    requiresCreditCard: requires_credit_card,
    methodChangePossible: method_change_possible,
  }
}
export const transformPayInMethodDtos = (
  payInMethods: Array<PayInMethodDto>,
): Array<PayInMethodModel> => payInMethods.map(transformPayInMethodDto)
