import { ThreeDS2Provider } from 'constants/checkout-configuration'
import {
  AdyenNative3DSConfigDto,
  CheckoutConfigurationDto,
  ThreeDS2ConfigurationDto,
} from 'types/dtos/checkout-configuration'
import {
  CheckoutConfigurationModel,
  CheckoutNativeConfigurationModel,
} from 'types/models/checkout-configuration'

const transformThreeDS2Config = ({ provider_config, provider }: ThreeDS2ConfigurationDto) => ({
  providerConfig: {
    apiKey: provider_config.api_key,
    site: provider_config.site,
  },
  provider,
})

export const transformCheckoutConfigurationDto = ({
  threeds2,
}: CheckoutConfigurationDto): CheckoutConfigurationModel => {
  return {
    threeDS2: threeds2 ? transformThreeDS2Config(threeds2) : null,
  }
}

export const transformNative3DSConfigurationDto = (
  config: AdyenNative3DSConfigDto,
): CheckoutNativeConfigurationModel => {
  return {
    threeDS2: {
      provider: ThreeDS2Provider.AdyenBank, // TODO: remove provider after payment orchestration scale
      providerConfig: {
        apiKey: config?.api_key,
        site: config?.site,
      },
    },
  }
}
