import { put, takeLatest } from 'redux-saga/effects'
import { select, all, call, take } from 'typed-redux-saga'

import * as api from 'data/api'
import { transformPayInMethodDtos } from 'data/transformers/pay-in-method'

import { PayInMethodCode } from 'constants/pay-in-method'
import { CreditCardAddReturnResult } from 'constants/credit-card'

import { actions } from './slice'
import * as selectors from './selectors'
import * as statelessActions from './actions'

import * as checkoutSelectors from '../selectors'
import { actions as checkoutActions } from '../slice'

export function* fetchPayInMethods({
  payload,
}: ReturnType<typeof actions.fetchPayInMethodsRequest>) {
  const { transactionId } = payload

  const payInMethodsResponse = yield* call(api.getTransactionPayInMethods, transactionId)

  if ('errors' in payInMethodsResponse) {
    yield put(actions.fetchPayInMethodsFailure())

    return
  }

  const payInMethods = transformPayInMethodDtos(payInMethodsResponse.pay_in_methods)

  yield put(
    actions.fetchPayInMethodsSuccess({
      payInMethods,
    }),
  )
}

export function* handleCardAddAuthResult({
  payload,
}: ReturnType<typeof actions.handleCardAddAuthResult>) {
  const { cardId, cardAddResult, transactionId } = payload

  const isCardAddFailed = cardAddResult !== CreditCardAddReturnResult.Success || !cardId

  yield put(
    checkoutActions.updateCheckoutDataRequest({
      transactionId,
    }),
  )

  yield put(
    actions.fetchPayInMethodsRequest({
      transactionId,
    }),
  )

  if (isCardAddFailed) return

  yield all([
    take(checkoutActions.updateCheckoutDataSuccess),
    take(actions.fetchPayInMethodsSuccess),
  ])

  // This action should happen if:
  // * card add is successful (there is a card to set as selected).
  // * after both checkout data and pay in methods are fetched.
  // This way client can be sure that data in front-end and back-end is in sync and there no race conditions.
  yield put(statelessActions.selectCreditCardRequest({ creditCardId: cardId }))
}

export function* selectCreditCard({
  payload,
}: ReturnType<typeof statelessActions.selectCreditCardRequest>) {
  const { creditCardId } = payload

  const payInMethods = yield* select(selectors.getPayInMethods)
  const transactionId = yield* select(checkoutSelectors.getTransactionId)

  if (!transactionId) return

  const cardPayInMethod = payInMethods.find(({ code }) => code === PayInMethodCode.CreditCard)

  if (!cardPayInMethod) return

  yield put(
    checkoutActions.updateCheckoutDataRequest({
      transactionId,
      updatedCheckoutData: {
        creditCardId,
        payInMethodId: cardPayInMethod.id,
      },
    }),
  )
}

export default function* saga() {
  yield takeLatest(statelessActions.selectCreditCardRequest, selectCreditCard)
  yield takeLatest(actions.handleCardAddAuthResult, handleCardAddAuthResult)
  yield takeLatest(actions.fetchPayInMethodsRequest, fetchPayInMethods)
}
