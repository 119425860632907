import { apply, put, race, take, takeLatest } from 'redux-saga/effects'
import { select, call } from 'typed-redux-saga'

import { UiState } from '@marketplace-web/shared/ui-helpers'
import * as api from 'data/api'
import { transformCountryBoundsDto } from 'data/transformers/country-bounds'
import { transformPickupPointsDto } from 'data/transformers/pickup-point'
import { transformShippingOptionDtos } from 'data/transformers/shipping-option'
import { PickupPointModel } from 'types/models/pickup-point'

import { geocodeAddress } from 'data/utils/google'
import * as sessionSelectors from 'state/session/selectors'
import { Screen } from 'constants/tracking/screens'
import { tracker } from '_libs/common/tracker'
import { buyerLoadShippingPointsEvent } from '_libs/common/event-tracker/events'

import * as checkoutSelectors from '../selectors'
import { actions, statelessActions, selectors } from './index'
import { getShippingPointsCount } from '../utils'

export function* fetchCountryBounds() {
  const sessionCountryId = yield* select(sessionSelectors.getCountryId)
  const address = yield* select(checkoutSelectors.getAddress)

  const countryId = address?.countryId || sessionCountryId

  if (!countryId) return

  const response = yield* call(api.getCountryBounds, countryId)

  if ('errors' in response) return

  const countryBounds = transformCountryBoundsDto(response.country_bounds)

  yield put(actions.fetchCountryBoundsSuccess({ countryBounds }))
}

export function* fetchNearbyShippingPoints({
  payload,
}: ReturnType<typeof actions.fetchNearbyShippingPointsRequest>) {
  const address = yield* select(checkoutSelectors.getAddress)
  const userCountryCode = yield* select(sessionSelectors.getUserCountryCode)
  const selectedShippingPoint = yield* select(selectors.getSelectedShippingPoint)
  const formattedAddress = yield* select(checkoutSelectors.getFormattedAddress)

  const countryCode = address?.countryIsoCode || userCountryCode
  const { coordinates, shouldLabelNearestPoints } = payload

  let latitude: number | undefined
  let longitude: number | undefined

  if (coordinates) {
    latitude = coordinates.latitude
    longitude = coordinates.longitude
  } else if (selectedShippingPoint) {
    latitude = Number(selectedShippingPoint.latitude)
    longitude = Number(selectedShippingPoint.longitude)
  } else if (formattedAddress) {
    const geocodedAddress = yield* call(geocodeAddress, formattedAddress)

    latitude = geocodedAddress?.geometry.location.lat()
    longitude = geocodedAddress?.geometry.location.lng()
  }

  yield put(
    statelessActions.fetchNearbyShippingOptionsRequest({
      countryCode,
      longitude,
      latitude,
      shouldLabelNearestPoints,
    }),
  )
}

export function* fetchNearbyPointsByElement({
  payload: { coordinates, element, shouldLabelNearestPoints },
}: ReturnType<typeof statelessActions.fetchNearbyPointsByElementRequest>) {
  yield put(actions.fetchNearbyShippingPointsRequest({ coordinates, shouldLabelNearestPoints }))
  yield put(actions.setPickupModalElementUiState({ element, uiState: UiState.Pending }))

  const raceResult = yield race({
    optionsSuccess: take(actions.fetchNearbyShippingOptionsSuccess),
    optionsFailure: take(actions.fetchNearbyShippingOptionsFailure),
  })

  if (raceResult.optionsSuccess) {
    yield put(actions.setPickupModalElementUiState({ element, uiState: UiState.Success }))
  } else if (raceResult.optionsFailure) {
    yield put(actions.setPickupModalElementUiState({ element, uiState: UiState.Failure }))
  }
}

export function* trackShippingPointsLoad({
  payload: { latitude, longitude, pickupPoints },
}: ReturnType<typeof statelessActions.trackShippingPointsLoad>) {
  const transactionId = yield* select(checkoutSelectors.getTransactionId)
  const shippingOptions = yield* select(selectors.getShippingOptions)

  if (!latitude || !longitude || !pickupPoints) return

  const shippingPointsCount = getShippingPointsCount(pickupPoints, shippingOptions)

  const loadShippingPointsEvent = buyerLoadShippingPointsEvent({
    transactionId,
    screen: Screen.PickupPointMap,
    shippingPointsCount: shippingPointsCount ? JSON.stringify(shippingPointsCount) : null,
    latitude: Number(latitude),
    longitude: Number(longitude),
  })

  yield apply(tracker, tracker.track, [loadShippingPointsEvent])
}

export function* fetchNearbyShippingOptions({
  payload,
}: ReturnType<typeof statelessActions.fetchNearbyShippingOptionsRequest>) {
  const { latitude, longitude } = payload

  let nearbyShippingPoints: Array<PickupPointModel> = []

  const transactionId = yield* select(checkoutSelectors.getTransactionId)

  if (!transactionId) return

  try {
    const response = yield* call(api.getNearbyShippingOptions, {
      transactionId,
      ...payload,
    })

    if ('errors' in response || !response.nearby_shipping_points.length) {
      yield put(actions.fetchNearbyShippingOptionsFailure())

      return
    }

    const {
      nearby_shipping_points,
      nearby_shipping_options,
      suggested_shipping_point_code,
      suggested_rate_uuid,
    } = response

    nearbyShippingPoints = transformPickupPointsDto(nearby_shipping_points)
    const nearbyShippingOptions = transformShippingOptionDtos(nearby_shipping_options)
    const suggestedShippingPointCode = suggested_shipping_point_code
    const suggestedRateUuid = suggested_rate_uuid

    yield put(
      actions.fetchNearbyShippingOptionsSuccess({
        nearbyShippingPoints,
        nearbyShippingOptions,
        suggestedShippingPointCode,
        suggestedRateUuid,
      }),
    )
  } finally {
    yield put(
      statelessActions.trackShippingPointsLoad({
        latitude,
        longitude,
        pickupPoints: nearbyShippingPoints,
      }),
    )
  }
}

export default function* saga() {
  yield takeLatest(statelessActions.fetchCountryBoundsRequest, fetchCountryBounds)
  yield takeLatest(statelessActions.trackShippingPointsLoad, trackShippingPointsLoad)
  yield takeLatest(statelessActions.fetchNearbyPointsByElementRequest, fetchNearbyPointsByElement)
  yield takeLatest(actions.fetchNearbyShippingPointsRequest, fetchNearbyShippingPoints)
  yield takeLatest(statelessActions.fetchNearbyShippingOptionsRequest, fetchNearbyShippingOptions)
}
