import { ItemAlertStatus } from 'constants/item'
import { ItemStatus } from 'types/item-status'

export const transformAlertToItemStatus = (alert: ItemAlertStatus): ItemStatus => {
  switch (alert) {
    case ItemAlertStatus.DarkGray:
      return 'hidden_from_catalog'
    case ItemAlertStatus.DelayedPublication:
      return 'delayed'
    default:
      return alert
  }
}
