export const stateName = 'checkout' as const

export enum DeliveryType {
  Home = 1,
  PickupPoint = 2,
  NoShipping = 3,
  Custom = 4,
}

export enum FieldName {
  Address = 'to_address',
  PickupPoint = 'pickup_point',
  PaymentMethod = 'payment_method',
}

export const PAYMENT_POLL_DELAY = 1000
export const MAX_PAYMENT_POLL_ATTEMPT_COUNT = 5
export const CREDIT_CARD_EVENT_TRACKING_CODE: string = 'cc' as const
