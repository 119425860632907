import { OfflineVerificationModel } from 'types/models/offline-verification'
import { OfflineVerificationDto } from 'types/dtos/offline-verification'

import { transformCurrencyAmountDto } from './currency-amount'

export const transformOfflineVerificationDto = ({
  fee,
  enabled,
}: OfflineVerificationDto): OfflineVerificationModel => {
  return {
    fee: transformCurrencyAmountDto(fee),
    enabled,
  }
}
