export enum PayInMethodCode {
  CreditCard = 'CREDIT_CARD',
  MangoPayPaypal = 'MANGOPAY_PAYPAL',
  Sofort = 'SOFORT',
  Tink = 'TINK',
  Ideal = 'IDEAL',
  Maual = 'MANUAL',
  Wallet = 'WALLET',
  P24 = 'P24',
  Trustly = 'TRUSTLY',
  Payconiq = 'PAYCONIQ',
  BlikDirect = 'BLIK_DIRECT',
  ApplePay = 'APPLE_PAY',
  GooglePay = 'GOOGLE_PAY',
}
