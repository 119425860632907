import { getItemThumbnail } from 'data/utils/item'
import { CheckoutItemDto } from 'types/dtos/checkout-item'
import { CheckoutItemModel } from 'types/models/checkout-item'

import { transformCurrencyAmountDto } from './currency-amount'

export const transformCheckoutItemDto = (item: CheckoutItemDto): CheckoutItemModel => ({
  id: item.id,
  size: item.size,
  title: item.title,
  status: item.status,
  brandId: item.brand_id,
  brand: item.brand_title,
  statusId: item.status_id,
  catalogId: item.catalog_id,
  disposalConditions: item.disposal_conditions,
  price: transformCurrencyAmountDto(item.price),
  thumbnailUrl: item.photo ? getItemThumbnail([item.photo]) : null,
})

export const transformCheckoutItemDtos = (
  items: Array<CheckoutItemDto>,
): Array<CheckoutItemModel> => items.map(transformCheckoutItemDto)
