import { createSelector } from '@reduxjs/toolkit'

import { PayInMethodCode } from 'constants/pay-in-method'

import { AllState, CheckoutState } from '../types'
import { stateName } from '../constants'

const checkoutState = (state: AllState): CheckoutState => state[stateName]
const localState = (state: AllState) => checkoutState(state).payment

export const getFormState = (state: AllState) => localState(state).formState
export const getCardAddAuth = (state: AllState) => localState(state).cardAddAuth
export const getPayInMethods = (state: AllState) => localState(state).payInMethodsState.payInMethods
export const getIsCardSingleUseOverriden = (state: AllState) =>
  localState(state).isCardSingleUseOverriden

export const getIsCardExpired = createSelector(getFormState, ({ errors }) => errors.isCardExpired)

export const getCardPayInMethod = createSelector(getPayInMethods, payInMethods => {
  return payInMethods.find(({ code }) => code === PayInMethodCode.CreditCard)
})
