import { AddressFieldConfiguration } from 'types/models/address'

import { AddressField } from './address'

export const CHECKOUT_LOAD_TIME_TRACKING_DETAILS_STORAGE_KEY = 'checkout_load_time_tracking_details'

export const SINGLE_CHECKOUT_LOAD_TIME_TRACKING_DETAILS_STORAGE_KEY =
  'single_checkout_load_time_tracking_details'

export const CREDIT_CARD_ADD_MODAL_LOAD_TIME_TRACKING_DETAILS_STORAGE_KEY =
  'credit_card_add_modal_load_time_tracking_details'

export const PAYMENT_OPTIONS_LOAD_TIME_TRACKING_DETAILS_STORAGE_KEY =
  'payment_options_load_time_tracking_details'

export const addressFieldConfig: Array<AddressFieldConfiguration> = [
  {
    field: AddressField.Country,
  },
  {
    field: AddressField.Name,
    required: true,
  },
  {
    field: AddressField.Line1,
    required: true,
  },
  {
    field: AddressField.Line2,
  },
  {
    field: AddressField.PostalCode,
    required: true,
  },
  {
    field: AddressField.City,
    required: true,
  },
]

export enum FieldName {
  Address = 'to_address',
  PickupPoint = 'pickup_point',
  PaymentMethod = 'payment_method',
}

export enum DeliveryType {
  Home = 1,
  PickupPoint = 2,
  NoShipping = 3,
  Custom = 4,
}

export enum DeliveryOption {
  Pickup = 'pickup',
  Home = 'home',
  Meetup = 'meetup',
  Custom = 'custom',
}

export enum PickupModalElement {
  Modal = 'modal',
  SearchArea = 'search_area',
  UserLocation = 'user_location',
  Search = 'search',
}
