import { PickupPointModel } from 'types/models/pickup-point'
import { ShippingOptionModel } from 'types/models/shipping-option'

type ShippingPointsCount = {
  [code: string]: number
}

export const getShippingPointsCount = (
  nearbyPoints: Array<PickupPointModel>,
  shippingOptions: Array<ShippingOptionModel>,
) => {
  if (!nearbyPoints.length || !shippingOptions.length) return null

  const shippingPointsCount: ShippingPointsCount = {}

  shippingOptions.forEach(option => {
    if (!option.carrierCode) return

    shippingPointsCount[option.carrierCode] = nearbyPoints.filter(
      nearbyPoint => option.carrierCode === nearbyPoint.point.carrierCode,
    ).length
  })

  return shippingPointsCount
}
