import { ElectronicsVerificationModel } from 'types/models/electronics-verification'
import { ElectronicsVerificationDto } from 'types/dtos/electronics-verification'

import { transformCurrencyAmountDto } from './currency-amount'

export const transformElectronicsVerificationDto = ({
  fee,
  enabled,
}: ElectronicsVerificationDto): ElectronicsVerificationModel => {
  return {
    fee: transformCurrencyAmountDto(fee),
    enabled,
  }
}
