import { CreditCardDto } from 'types/dtos/credit-card'
import { CreditCardModel } from 'types/models/credit-card'

export const transformCreditCardDto = (card: CreditCardDto): CreditCardModel => {
  return {
    id: `${card.external_code || card.id}`,
    lastFour: card.last4 || card.last_four,
    isDefault: card.default ?? card.is_default,
    brand: card.brand,
    expirationMonth: card.expiration_month,
    expirationYear: card.expiration_year,
    isSingleUse: card.single_use,
    expired: card.expired,
  }
}

export const transformCreditCardDtos = (cards: Array<CreditCardDto>): Array<CreditCardModel> =>
  cards.map(transformCreditCardDto)
