import { PickupPointDto } from 'types/dtos/pickup-point'
import { PickupPointModel } from 'types/models/pickup-point'

import { transformShippingPointDto } from './shipping-point'

export const transformPickupPointDto = ({
  distance,
  preferred,
  point,
  distance_unit,
  distance_label,
  package_type_id,
}: PickupPointDto): PickupPointModel => ({
  distance,
  preferred,
  distanceUnit: distance_unit,
  distanceLabel: distance_label,
  packageTypeId: package_type_id,
  point: transformShippingPointDto(point),
})

export const transformPickupPointsDto = (
  pickupPointDto: Array<PickupPointDto>,
): Array<PickupPointModel> => pickupPointDto.map(transformPickupPointDto)
