import { combineReducers } from '@reduxjs/toolkit'
import { all } from 'redux-saga/effects'

import checkoutSagas from './sagas'
import shippingSagas from './shipping/sagas'
import paymentSagas from './payment/sagas'
import * as constants from './constants'
import * as checkoutStatelessActions from './actions'
import * as checkoutSelectors from './selectors'
import checkoutReducer, { actions as checkoutActions } from './slice'
import shippingReducer, {
  actions as shippingActions,
  selectors as shippingSelectors,
  statelessActions as shippingStatelessActions,
} from './shipping'
import paymentReducer, {
  actions as paymentActions,
  selectors as paymentSelectors,
  statelessActions as paymentStatelessActions,
} from './payment'

const actions = {
  ...checkoutActions,
  ...shippingActions,
  ...paymentActions,
}

const reducer = combineReducers({
  checkout: checkoutReducer,
  shipping: shippingReducer,
  payment: paymentReducer,
})

const selectors = {
  ...checkoutSelectors,
  ...shippingSelectors,
  ...paymentSelectors,
}

const statelessActions: typeof checkoutStatelessActions &
  typeof paymentStatelessActions &
  typeof shippingStatelessActions = {
  ...checkoutStatelessActions,
  ...paymentStatelessActions,
  ...shippingStatelessActions,
}

function* sagas() {
  yield all([checkoutSagas(), shippingSagas(), paymentSagas()])
}

export { constants, statelessActions, actions, selectors, sagas }
export * from './types'

export const plug = { [constants.stateName]: reducer }

export default reducer
